import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, functions } from '../firebase';
import { Button } from '../@/components/ui/button';
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardDescription,
} from '../@/components/ui/card';
import { X, Check, Waves, Droplet, Globe } from 'lucide-react';
import { Capacitor } from '@capacitor/core';
import { loadStripe } from '@stripe/stripe-js';
import {
  Purchases,
  PurchasesPackage,
  PurchasesOffering,
  PURCHASES_ERROR_CODE,
} from '@revenuecat/purchases-capacitor';
import SegmentedPicker from '../components/SegmentedPicker';
import { httpsCallable } from 'firebase/functions';
import { Browser } from '@capacitor/browser';

interface CheckoutSessionResult {
  sessionId: string;
}

interface WebPackage {
  identifier: string;
  packageType: string;
  product: {
    price: number;
    currencyCode: string;
    identifier: string;
    subscriptionPeriod?: string;
  };
}

interface WebOffering {
  identifier: string;
  serverDescription?: string;
  metadata: Record<string, unknown>;
  availablePackages: WebPackage[];
  lifetime?: WebPackage;
  annual?: WebPackage;
  sixMonth?: WebPackage;
  threeMonth?: WebPackage;
  twoMonth?: WebPackage;
  monthly?: WebPackage;
  weekly?: WebPackage;
}

type Package = WebPackage | PurchasesPackage;
type Offering = WebOffering | PurchasesOffering;

function isWebOffering(offering: Offering): offering is WebOffering {
  return 'availablePackages' in offering;
}

const getPackages = (offering: Offering): (WebPackage | PurchasesPackage)[] => {
  if (isWebOffering(offering)) {
    return offering.availablePackages;
  } else {
    return Object.values(offering).filter(
      (pkg): pkg is PurchasesPackage => pkg instanceof Object
    );
  }
};

const PaymentPage: React.FC = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const isNative = Capacitor.isNativePlatform();
  const [offerings, setOfferings] = useState<Record<string, Offering>>({});
  const [selectedOffering, setSelectedOffering] = useState<string | null>(null);
  const [selectedPackage, setSelectedPackage] = useState<Package | null>(null);

  const RIVER_ICON = Droplet;
  const LAKE_ICON = Waves;
  const OCEAN_ICON = Globe;

  useEffect(() => {
    fetchOfferings();
  }, []);

  useLayoutEffect(() => {
    const originalOverflow = document.body.style.overflow;
    const originalHeight = document.body.style.height;

    document.body.style.overflow = 'hidden';
    document.body.style.height = '100vh';

    return () => {
      document.body.style.overflow = originalOverflow;
      document.body.style.height = originalHeight;
    };
  }, []);

  useEffect(() => {
    if (selectedOffering && offerings[selectedOffering]) {
      const offering = offerings[selectedOffering];
      const packages = getPackages(offering);

      const annualPackage = packages.find(
        pkg =>
          pkg.packageType === 'ANNUAL' ||
          pkg.product.subscriptionPeriod?.toLowerCase().includes('year')
      );
      if (annualPackage) {
        setSelectedPackage(annualPackage);
      }
    }
  }, [selectedOffering, offerings]);

  const handlePackageSelection = (pkg: Package) => {
    setSelectedPackage(pkg);
  };

  const fetchOfferings = async () => {
    try {
      if (isNative) {
        const allOfferings = await Purchases.getOfferings();
        if (allOfferings.all && Object.keys(allOfferings.all).length > 0) {
          setOfferings(allOfferings.all);
          setSelectedOffering(Object.keys(allOfferings.all)[0]);
        } else {
          setErrorMessage('Aucune offre disponible.');
        }
      } else {
        const isDevelopment = process.env.NODE_ENV === 'development';
        const webOfferings: Record<string, WebOffering> = {
          riviere: {
            identifier: 'riviere',
            serverDescription: 'Offre Rivière',
            metadata: {},
            availablePackages: [
              createWebPackage(
                'riviere',
                'mensuel',
                isDevelopment
                  ? 'price_1PuNV5Fltdj3gSrOuMZKploE'
                  : 'price_1PzLdAFltdj3gSrOh6N0xLsR',
                9.95
              ),
              createWebPackage(
                'riviere',
                'annuel',
                isDevelopment
                  ? 'price_1PuNdHFltdj3gSrOHF6Eu1jb'
                  : 'price_1PzLd9Fltdj3gSrOVY3DUBIX',
                99.95
              ),
            ],
          },
          lac: {
            identifier: 'lac',
            serverDescription: 'Offre Lac',
            metadata: {},
            availablePackages: [
              createWebPackage(
                'lac',
                'mensuel',
                isDevelopment
                  ? 'price_1PuNdiFltdj3gSrONI8G66dw'
                  : 'price_1PzLd7Fltdj3gSrOITNfaL5i',
                19.95
              ),
              createWebPackage(
                'lac',
                'annuel',
                isDevelopment
                  ? 'price_1PuNelFltdj3gSrO9hPX0y4y'
                  : 'price_1PzLd5Fltdj3gSrOiypqGMbo',
                199.95
              ),
            ],
          },
          ocean: {
            identifier: 'ocean',
            serverDescription: 'Offre Océan',
            metadata: {},
            availablePackages: [
              createWebPackage(
                'ocean',
                'mensuel',
                isDevelopment
                  ? 'price_1PuNfNFltdj3gSrOxIRxYYoo'
                  : 'price_1PzLd4Fltdj3gSrOsJFV3Hx9',
                29.95
              ),
              createWebPackage(
                'ocean',
                'annuel',
                isDevelopment
                  ? 'price_1PuNfpFltdj3gSrOeqhywNB4'
                  : 'price_1PzLd0Fltdj3gSrOUFsQbmWg',
                299.95
              ),
            ],
          },
        };
        setOfferings(webOfferings);
        setSelectedOffering('riviere');
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des offres:', error);
      setErrorMessage('Impossible de charger les offres. Veuillez réessayer.');
    }
  };

  const createWebPackage = (
    offering: string,
    period: string,
    stripePriceId: string,
    price: number
  ): WebPackage => ({
    identifier: `${offering}:fidelipass-${offering}-${period}`,
    packageType: period === 'annuel' ? 'ANNUAL' : 'MONTHLY',
    product: {
      price,
      currencyCode: 'CHF',
      identifier: stripePriceId,
      subscriptionPeriod: period === 'annuel' ? 'P1Y' : 'P1M',
    },
  });

  const handlePurchase = async () => {
    if (!selectedPackage) return;

    try {
      if (isNative) {
        const purchaseResult = await Purchases.purchasePackage({
          aPackage: selectedPackage as PurchasesPackage,
        });

        // Vérifier les droits immédiatement après l'achat
        const hasEntitlement =
          typeof purchaseResult.customerInfo.entitlements.active[
            'riviere_access'
          ] !== 'undefined' ||
          typeof purchaseResult.customerInfo.entitlements.active[
            'lac_access'
          ] !== 'undefined' ||
          typeof purchaseResult.customerInfo.entitlements.active[
            'ocean_access'
          ] !== 'undefined';

        if (hasEntitlement) {
          // Mettre à jour l'état global de l'application
          // Utilisez ici la méthode appropriée pour mettre à jour votre état global
          // Par exemple : updateAppEntitlementState(true);

          // await queryClient.invalidateQueries({ queryKey: ['entitlements'] });

          // Naviguer vers la page des profils marchands
          const customerInfo = await Purchases.getCustomerInfo();
          // Update your UI or app state based on the new info
          //console.log('Updated CustomerInfo:', customerInfo);

          setTimeout(() => {
            // Mettre à jour l'état global
            // updateAppEntitlementState(true);
            navigate('/settings');
            window.location.reload();
          }, 1000); // Attendre 1 seconde
        } else {
          setErrorMessage(
            "L'achat a réussi mais les droits n'ont pas été activés immédiatement. Veuillez patienter quelques instants ou redémarrer l'application."
          );
        }
      } else {
        const user = auth.currentUser;
        if (!user) {
          setErrorMessage('Vous devez être connecté pour effectuer un achat.');
          return;
        }

        const createCheckoutSession = httpsCallable<any, CheckoutSessionResult>(
          functions,
          'createCheckoutSession'
        );
        const result = await createCheckoutSession({
          priceId: (selectedPackage as WebPackage).product.identifier,
          successUrl: window.location.origin + '/merchant-profiles',
          cancelUrl: window.location.origin + '/payment',
        });

        if (result.data && result.data.sessionId) {
          // await queryClient.invalidateQueries({ queryKey: ['entitlements'] });
          const stripePublicKey =
            process.env.NODE_ENV === 'development'
              ? 'pk_test_51PkTXPFltdj3gSrOLn7nGCPo7js3ndKQbLhyR805TJSpLl7ENKhj893qW0TYYXz4EvAJSCb0QV4WjgbS19xOsFJO00XLP1k7Nc'
              : process.env.REACT_APP_STRIPE_PUBLIC_KEY || '';
          const stripe = await loadStripe(stripePublicKey);
          if (stripe) {
            await stripe.redirectToCheckout({
              sessionId: result.data.sessionId,
            });
          } else {
            setErrorMessage('Erreur lors du chargement de Stripe.');
          }
        } else {
          setErrorMessage(
            'Erreur lors de la création de la session de paiement.'
          );
        }
      }
    } catch (error: any) {
      if (error.code === PURCHASES_ERROR_CODE.PURCHASE_CANCELLED_ERROR) {
        setErrorMessage("L'achat a été annulé.");
      } else {
        console.error("Erreur lors de l'achat:", error);
        setErrorMessage(
          "Une erreur est survenue lors de l'achat. Veuillez réessayer."
        );
      }
    }
  };

  const openLink = async (url: string) => {
    if (isNative) {
      await Browser.open({ url });
    } else {
      window.open(url, '_blank');
    }
  };

  const renderOfferingDetails = (offering: Offering) => {
    const offeringId = offering.identifier;
    let title, cardCount, Icon;

    switch (offeringId) {
      case 'riviere':
        title = 'Rivière';
        cardCount = 100;
        Icon = RIVER_ICON;
        break;
      case 'lac':
        title = 'Lac';
        cardCount = 250;
        Icon = LAKE_ICON;
        break;
      case 'ocean':
        title = 'Océan';
        cardCount = 500;
        Icon = OCEAN_ICON;
        break;
      default:
        title = offering.serverDescription || 'Inconnu';
        cardCount = 'Variable';
        Icon = null;
    }

    const packages = getPackages(offering);

    const monthlyPackage = packages.find(
      pkg =>
        pkg.packageType === 'MONTHLY' ||
        pkg.product.subscriptionPeriod?.toLowerCase().includes('month')
    );
    const annualPackage = packages.find(
      pkg =>
        pkg.packageType === 'ANNUAL' ||
        pkg.product.subscriptionPeriod?.toLowerCase().includes('year')
    );

    const calculateSavings = () => {
      if (monthlyPackage && annualPackage) {
        const monthlyPrice = monthlyPackage.product.price;
        const annualPrice = annualPackage.product.price;
        const savings = (1 - annualPrice / (monthlyPrice * 12)) * 100;
        return Math.round(savings);
      }
      return 0;
    };

    return (
      <Card key={offeringId} className="mb-6">
        <CardHeader>
          <CardTitle className="flex items-center">
            {Icon && <Icon className="mr-2 h-6 w-6" />}
            {title}
          </CardTitle>
        </CardHeader>
        <CardContent>
          <ul className="space-y-2 mb-6">
            <li className="flex items-start">
              <Check className="h-6 w-6 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
              <span className="text">
                {cardCount === 'illimitées' ? (
                  'Cartes de fidélité client illimitées'
                ) : (
                  <>
                    <span className="font-bold">{cardCount}</span> Nouvelles
                    cartes de fidélité client par mois
                  </>
                )}
              </span>
            </li>
            <li className="flex items-start">
              <Check className="h-6 w-6 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
              <span className="text-normal">
                Commerce mis en avant auprès de notre communauté
              </span>
            </li>
            <li className="flex items-start">
              <Check className="h-6 w-6 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
              <span className="text-normal">Statistiques en temps réel</span>
            </li>
            <li className="flex items-start">
              <Check className="h-6 w-6 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
              <span className="text-normal">
                Support client{' '}
                {offeringId === 'ocean' ? 'dédié' : 'prioritaire'}
              </span>
            </li>
          </ul>
          <div className="space-y-4">
            {annualPackage && (
              <Card
                className={`cursor-pointer transition-colors ${
                  selectedPackage === annualPackage
                    ? 'bg-primary text-primary-foreground'
                    : ''
                }`}
                onClick={() => handlePackageSelection(annualPackage)}
              >
                <CardHeader>
                  <CardTitle>Annuel</CardTitle>
                  <CardDescription
                    className={
                      selectedPackage === annualPackage
                        ? 'text-primary-foreground'
                        : ''
                    }
                  >
                    {annualPackage.product.price.toFixed(2)}{' '}
                    {annualPackage.product.currencyCode} / an
                    <br />
                    <span className="text-green-500">
                      Économisez {calculateSavings()}% par rapport au mensuel
                    </span>
                  </CardDescription>
                </CardHeader>
              </Card>
            )}
            {monthlyPackage && (
              <Card
                className={`cursor-pointer transition-colors ${
                  selectedPackage === monthlyPackage
                    ? 'bg-primary text-primary-foreground'
                    : ''
                }`}
                onClick={() => handlePackageSelection(monthlyPackage)}
              >
                <CardHeader>
                  <CardTitle>Mensuel</CardTitle>
                  <CardDescription
                    className={
                      selectedPackage === monthlyPackage
                        ? 'text-primary-foreground'
                        : ''
                    }
                  >
                    {monthlyPackage.product.price.toFixed(2)}{' '}
                    {monthlyPackage.product.currencyCode} / mois
                  </CardDescription>
                </CardHeader>
              </Card>
            )}
          </div>
        </CardContent>
      </Card>
    );
  };

  const renderOfferingSelector = () => {
    const getOfferingLabel = (key: string) => {
      switch (key) {
        case 'riviere':
          return 'Rivière';
        case 'lac':
          return 'Lac';
        case 'ocean':
          return 'Océan';
        default:
          return key;
      }
    };

    return (
      <div className="mb-6">
        <SegmentedPicker
          options={Object.keys(offerings).map(getOfferingLabel)}
          value={selectedOffering ? getOfferingLabel(selectedOffering) : ''}
          onChange={label => {
            const key = Object.keys(offerings).find(
              k => getOfferingLabel(k) === label
            );
            if (key) setSelectedOffering(key);
          }}
        />
      </div>
    );
  };

  return (
    <div
      className={`fixed inset-0 flex flex-col bg-background ${
        isNative
          ? 'safe-area-inset-top safe-area-inset-left safe-area-inset-right'
          : ''
      }`}
    >
      <div className="bg-white z-10 p-4">
        <div className="flex justify-between items-center">
          <div className="w-12 h-12"></div> {/* Empty space for alignment */}
          <h1 className="text-2xl font-bold">Choisissez votre offre</h1>
          <Button
            variant="ghost"
            size="icon"
            className="rounded-full w-12 h-12"
            onClick={() => navigate('/settings')}
            aria-label="Fermer"
          >
            <X className="h-6 w-6" />
          </Button>
        </div>
      </div>
      <div className="flex-grow overflow-y-auto">
        <div className="p-4 pb-36 max-w-3xl mx-auto">
          {errorMessage ? (
            <p className="text-red-500 mt-2 text-center">{errorMessage}</p>
          ) : (
            <>
              {renderOfferingSelector()}
              {selectedOffering && offerings[selectedOffering] ? (
                renderOfferingDetails(offerings[selectedOffering])
              ) : (
                <p>Sélectionnez une offre pour voir les détails disponibles.</p>
              )}
            </>
          )}
        </div>
      </div>
      <div className="fixed bottom-0 left-0 right-0 bg-white p-4 shadow-md">
        <div className="max-w-3xl mx-auto">
          <p className="text-xs text-gray-600 mb-4 text-center">
            En vous abonnant, vous acceptez notre{' '}
            <button
              onClick={() =>
                openLink('https://fidelipassapp.com/app-privacy-policy')
              }
              className="text-primary hover:underline"
            >
              politique de confidentialité
            </button>
            , nos{' '}
            <button
              onClick={() =>
                openLink('https://fidelipassapp.com/app-terms-of-services')
              }
              className="text-primary hover:underline"
            >
              conditions générales d'utilisation
            </button>{' '}
            et nos{' '}
            <button
              onClick={() =>
                openLink('https://fidelipassapp.com/terms-of-sale')
              }
              className="text-primary hover:underline"
            >
              conditions générales de vente
            </button>
            .
          </p>
          <Button
            onClick={handlePurchase}
            className="w-full h-12"
            disabled={!selectedPackage}
          >
            Continuer
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
