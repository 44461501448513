import { z } from 'zod';

// Password validation schema
export const passwordSchema = z
  .string()
  .min(8, 'Le mot de passe doit contenir au moins 8 caractères')
  .regex(
    /(?=.*[a-z])/,
    'Le mot de passe doit contenir au moins une lettre minuscule'
  )
  .regex(
    /(?=.*[A-Z])/,
    'Le mot de passe doit contenir au moins une lettre majuscule'
  )
  .regex(/(?=.*\d)/, 'Le mot de passe doit contenir au moins un chiffre')
  .regex(
    /(?=.*[@$!%*?&_-])/,
    'Le mot de passe doit contenir au moins un caractère spécial parmi : @, $, !, %, *, ?, &, _, -'
  )
  .regex(/^[^\s]+$/, "Le mot de passe ne doit pas contenir d'espaces");

// Password requirements for UI display
export const passwordRequirements = [
  {
    id: 'length',
    label: 'Au moins 8 caractères',
    regex: /.{8,}/,
  },
  {
    id: 'lowercase',
    label: 'Au moins une lettre minuscule',
    regex: /(?=.*[a-z])/,
  },
  {
    id: 'uppercase',
    label: 'Au moins une lettre majuscule',
    regex: /(?=.*[A-Z])/,
  },
  {
    id: 'digit',
    label: 'Au moins un chiffre',
    regex: /(?=.*\d)/,
  },
  {
    id: 'special',
    label: 'Au moins un caractère spécial (@$!%*?&_-)',
    regex: /(?=.*[@$!%*?&_-])/,
  },
];

// Password confirmation schema
export const passwordConfirmSchema = z
  .object({
    password: passwordSchema,
    confirmPassword: z.string(),
  })
  .refine(data => data.password === data.confirmPassword, {
    message: 'Les mots de passe ne correspondent pas',
    path: ['confirmPassword'],
  });

// Email validation schema
export const emailSchema = z
  .string()
  .email('Adresse e-mail invalide')
  .max(254, "L'adresse e-mail ne doit pas dépasser 254 caractères")
  .regex(
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    "Format d'adresse e-mail invalide"
  )
  .refine(
    email => {
      const domain = email.split('@')[1];
      return !['tempmail.com', 'throwawaymail.com', 'yopmail.com'].includes(
        domain
      );
    },
    { message: 'Les adresses e-mail temporaires ne sont pas autorisées' }
  );
