import React, { useState, useCallback } from 'react';
import ShopList from '../components/ShopList';
import { Button } from '../@/components/ui/button';
import { Phone, Globe, Instagram, Facebook, Map } from 'lucide-react';
import { Shop } from '../App';
import { auth } from '../firebase';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from '../@/components/ui/dialog';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { useQuery } from '@tanstack/react-query';
import { fetchUserShops } from '../hooks/queries';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Header } from '../components/Header';
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from '../@/components/ui/card';

const WalletPage: React.FC = () => {
  const [showCompleted, setShowCompleted] = useState(false);
  const [selectedShop, setSelectedShop] = useState<Shop | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const isNative = Capacitor.isNativePlatform();
  const location = useLocation();
  const [isSpinning, setIsSpinning] = useState(false);
  const navigate = useNavigate();

  const user = auth.currentUser;

  const {
    data: shops = [],
    isLoading,
    isRefetching,
    refetch,
  } = useQuery({
    queryKey: ['shops', user?.uid],
    queryFn: () => fetchUserShops(user?.uid || ''),
    enabled: !!user?.uid,
    staleTime: Infinity,
    gcTime: 30 * 60 * 1000,
    // Ajoutez ces options pour mieux contrôler le comportement de rechargement
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  // Effet pour détecter le retour depuis la page de scan
  useEffect(() => {
    if (location.state?.from === '/scan') {
      refetch();
      // Utilisez navigate à la place de history.replace
      navigate('/', { replace: true, state: undefined });
    }
  }, [location.state, refetch, navigate]);

  const handleManualRefresh = useCallback(async () => {
    setIsSpinning(true);
    await refetch();
    // Attendre au moins 500ms après la fin du fetching
    setTimeout(() => {
      setIsSpinning(false);
    }, 500);
  }, [refetch]);

  const handleShopClick = (shop: Shop) => {
    setSelectedShop(shop);
    setIsDialogOpen(true);
  };

  const filteredShops = showCompleted
    ? shops.filter(shop => shop.progression === shop.bubbleCount)
    : shops;

  const toggleShowCompleted = useCallback(() => {
    setShowCompleted(prev => !prev);
  }, []);

  const openLink = async (url: string | undefined) => {
    if (!url) return;

    if (Capacitor.isNativePlatform()) {
      await Browser.open({ url });
    } else {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  const openInMaps = (shop: Shop | null) => {
    if (!shop) return;

    const address = `${shop.address}, ${shop.postalCode} ${shop.city}, ${shop.country}`;
    const encodedAddress = encodeURIComponent(address);

    const isAppleDevice = /iPhone|iPad|iPod|Macintosh/.test(
      navigator.userAgent
    );

    if (isAppleDevice) {
      window.location.href = `maps:0,0?q=${encodedAddress}`;
    } else {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`,
        '_blank'
      );
    }
  };

  const getMessage = () => {
    if (showCompleted) {
      return filteredShops.length === 0 ? (
        <div className="border-2 border-dashed border-gray-300 p-4 rounded-lg">
          <p className="font-medium text-gray-800 text-center">
            Aucune carte complétée pour le moment
          </p>
        </div>
      ) : (
        ''
      );
    }
    return shops.length === 0 && !isLoading ? (
      <div className="space-y-4">
        <div className="border-2 border-dashed border-gray-300 p-4 rounded-lg">
          <p className="font-medium mb-2 text-gray-800">
            Voici comment fonctionne une carte :
          </p>
          <Card className="mb-6 cursor-pointer shadow-sm">
            <CardHeader className="text-left">
              <CardTitle>
                Nom du Commerce
                <span className="ml-2 text-sm font-normal text-gray-500">
                  2 x
                </span>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="flex flex-wrap gap-2 mb-4">
                {[...Array(10)].map((_, i) => (
                  <div
                    key={i}
                    className={`w-6 h-6 rounded-full ${
                      i < 7 ? 'bg-black' : 'bg-gray-300'
                    }`}
                  />
                ))}
              </div>
              <div className="text-sm text-gray-500 text-left">7 / 10</div>
            </CardContent>
          </Card>
          <div className="space-y-3 text-sm text-gray-700 max-w-md mx-auto">
            <div className="flex items-start gap-2">
              <div className="w-4 h-4 rounded-full bg-black shrink-0 mt-1"></div>
              <p className="flex-1 leading-tight">
                Chaque point noir représente un tampon obtenu lors d'un achat.
                La valeur du tampon est définie par le commerçant
              </p>
            </div>
            <div className="flex items-start gap-2">
              <div className="w-4 h-4 rounded-full bg-gray-300 shrink-0 mt-1"></div>
              <p className="flex-1 leading-tight">
                Les points gris indiquent les tampons qu'il vous reste à
                collecter
              </p>
            </div>
            <div className="flex items-start gap-2">
              <span className="text-gray-500 font-mono shrink-0 mt-1">2 x</span>
              <p className="flex-1 leading-tight">
                Le compteur indique le nombre de cartes complétées pour ce
                commerce
              </p>
            </div>
            <div className="flex items-start gap-2 pt-2 text-emerald-600 font-medium">
              <svg
                className="w-4 h-4 shrink-0 mt-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <p className="flex-1 leading-tight">
                Une récompense vous attend une fois la carte complétée !
              </p>
            </div>
          </div>

          <div className="mt-6 text-center">
            <Button
              onClick={() => navigate('/add-shop')}
              className="bg-black text-white hover:bg-gray-800 h-12"
            >
              Découvrir les commerces
            </Button>
          </div>
        </div>
      </div>
    ) : (
      ''
    );
  };

  return (
    <div
      className={`fixed inset-0 flex flex-col bg-background ${
        isNative
          ? 'safe-area-inset-top safe-area-inset-left safe-area-inset-right'
          : ''
      }`}
    >
      <Header
        variant="refresh"
        onRefresh={handleManualRefresh}
        isRefreshing={isRefetching || isSpinning}
        className="z-10"
      />
      <div className="px-4 pb-4">
        <Button
          onClick={toggleShowCompleted}
          className={`w-full h-12 transition-colors duration-200 ${
            showCompleted
              ? 'bg-black text-white'
              : isNative
              ? 'bg-transparent text-black border border-black'
              : 'bg-white text-black border border-black hover:bg-gray-100'
          }`}
          style={isNative && !showCompleted ? { backgroundColor: 'white' } : {}}
        >
          {showCompleted
            ? 'Afficher toutes les cartes'
            : 'Afficher cartes complétées'}
        </Button>
      </div>
      <div className="flex-grow overflow-y-auto">
        <div className="p-4">
          <div className="overflow-y-auto pb-16">
            {!isLoading && filteredShops.length === 0 ? (
              <div className="text-center text-gray-500 mt-4">
                {getMessage()}
              </div>
            ) : (
              <ShopList shops={filteredShops} onShopClick={handleShopClick} />
            )}
          </div>
        </div>
      </div>
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle className="text-left">
              {selectedShop?.name || 'Détails du magasin'}
            </DialogTitle>
            <DialogDescription className="text-left text-sm text-muted-foreground">
              {selectedShop?.address || 'Adresse non spécifiée'},{' '}
              {selectedShop?.postalCode || ''} {selectedShop?.city || ''}
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4 text-left">
            {selectedShop?.showDescription && selectedShop?.description && (
              <p className="text-left">{selectedShop.description}</p>
            )}
            {selectedShop?.offre && (
              <p className="mt-2 font-semibold text-left">
                Offre à la complétion : {selectedShop.offre}
              </p>
            )}
          </div>
          <div className="flex justify-start space-x-4 mt-4">
            <button
              onClick={() => openInMaps(selectedShop)}
              className="text-primary hover:text-primary-dark w-12 h-12 flex items-center justify-center"
            >
              <Map className="w-6 h-6" />
            </button>
            {selectedShop?.showPhone && selectedShop?.phone && (
              <a
                href={`tel:${selectedShop.phone}`}
                className="text-primary hover:text-primary-dark w-12 h-12 flex items-center justify-center"
              >
                <Phone className="w-6 h-6" />
              </a>
            )}
            {selectedShop?.showWebsite && selectedShop?.website && (
              <button
                onClick={() => openLink(selectedShop.website)}
                className="text-primary hover:text-primary-dark w-12 h-12 flex items-center justify-center"
              >
                <Globe className="w-6 h-6" />
              </button>
            )}
            {selectedShop?.showInstagram && selectedShop?.instagram && (
              <a
                href={selectedShop.instagram}
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:text-primary-dark w-12 h-12 flex items-center justify-center"
              >
                <Instagram className="w-6 h-6" />
              </a>
            )}
            {selectedShop?.showFacebook && selectedShop?.facebook && (
              <a
                href={selectedShop.facebook}
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:text-primary-dark w-12 h-12 flex items-center justify-center"
              >
                <Facebook className="w-6 h-6" />
              </a>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default WalletPage;
