// Spinner.tsx
import React from 'react';
import './spinner.css'; // Import your CSS file for styling

interface SpinnerProps {
  variant?: 'default' | 'button';
}

const Spinner: React.FC<SpinnerProps> = ({ variant = 'default' }) => {
  const containerClass =
    variant === 'button' ? 'spinner-container-button' : 'spinner-container';

  return (
    <div className={containerClass}>
      <div className="spinner"></div>
      {variant === 'button' ? <span>Chargement...</span> : <p>Chargement...</p>}
    </div>
  );
};

export default Spinner;
