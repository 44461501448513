import * as React from 'react';
import { cn } from 'src/@/lib/utils';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  isMobile?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, isMobile, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          'flex w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
          isMobile ? 'h-[48px]' : 'h-10',
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
Input.displayName = 'Input';

// Special password input that prevents spaces in all environments
const PasswordInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, isMobile, onChange, ...props }, ref) => {
    // Custom onChange handler that filters out spaces
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      // Create a new event with the spaces removed
      const newValue = e.target.value.replace(/\s/g, '');

      // Only update if the value actually changed (had spaces)
      if (newValue !== e.target.value) {
        e.target.value = newValue;
      }

      // Call the original onChange if it exists
      if (onChange) {
        onChange(e);
      }
    };

    // Custom keyDown handler to prevent space key
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === ' ' || e.code === 'Space') {
        e.preventDefault();
      }

      // Call the original onKeyDown if it exists
      if (props.onKeyDown) {
        props.onKeyDown(e);
      }
    };

    return (
      <Input
        type="password"
        className={className}
        isMobile={isMobile}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        ref={ref}
        {...props}
      />
    );
  }
);
PasswordInput.displayName = 'PasswordInput';

export { Input, PasswordInput };
