import React, { useEffect, useState } from 'react';
import { CheckCircle2, XCircle, AlertCircle, Shield } from 'lucide-react';
import { Capacitor } from '@capacitor/core';
import { passwordRequirements } from '../utils/validationSchemas';

interface PasswordRequirement {
  id: string;
  label: string;
  regex: RegExp;
  isMet: boolean;
}

interface PasswordRequirementsProps {
  password: string;
  className?: string;
}

const PasswordRequirements: React.FC<PasswordRequirementsProps> = ({
  password,
  className = '',
}) => {
  const isNative = Capacitor.isNativePlatform();
  const [animateItems, setAnimateItems] = useState(false);

  // Create requirements with isMet property based on current password
  const requirements: PasswordRequirement[] = passwordRequirements.map(req => ({
    ...req,
    isMet: req.regex.test(password),
  }));

  // Calculate overall strength
  const metRequirementsCount = requirements.filter(req => req.isMet).length;
  const strength = metRequirementsCount / requirements.length;

  // Determine strength color and text
  let strengthColor = 'bg-red-500';
  let strengthText = 'Faible';
  let strengthTextColor = 'text-red-600';
  let strengthIcon = (
    <AlertCircle className={`w-3.5 h-3.5 mr-1.5 ${strengthTextColor}`} />
  );

  if (strength === 1) {
    strengthColor = 'bg-green-500';
    strengthText = 'Fort';
    strengthTextColor = 'text-green-600';
    strengthIcon = (
      <Shield className={`w-3.5 h-3.5 mr-1.5 ${strengthTextColor}`} />
    );
  } else if (strength >= 0.6) {
    strengthColor = 'bg-yellow-500';
    strengthText = 'Moyen';
    strengthTextColor = 'text-yellow-600';
  } else if (strength >= 0.4) {
    strengthColor = 'bg-orange-500';
    strengthText = 'Faible';
    strengthTextColor = 'text-orange-600';
  }

  // Trigger animation after component mounts
  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimateItems(true);
    }, 50);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={`mt-2 text-sm rounded-lg border border-gray-100 ${
        isNative ? 'p-2' : 'p-2.5'
      } bg-gray-50 shadow-sm ${className}`}
    >
      {/* Password strength indicator */}
      <div className={`${isNative ? 'mb-1.5' : 'mb-2'}`}>
        <div className="flex items-center justify-between mb-1">
          <div className="flex items-center">
            {strengthIcon}
            <span className="text-gray-700 text-xs font-medium">
              Force du mot de passe:
            </span>
          </div>
          <span className={`text-xs font-medium ${strengthTextColor}`}>
            {strengthText}
          </span>
        </div>
        <div className="w-full h-1.5 bg-gray-200 rounded-full overflow-hidden">
          <div
            className={`h-full ${strengthColor} transition-all duration-500 ease-out`}
            style={{ width: `${strength * 100}%` }}
          ></div>
        </div>

        {/* Progress indicator - hide on mobile to save space */}
        {!isNative && (
          <div className="flex justify-between mt-0.5 px-1">
            <span className="text-[10px] text-gray-400">
              {metRequirementsCount} sur {requirements.length} critères
            </span>
            {strength === 1 && (
              <span className="text-[10px] text-green-600 font-medium">
                Parfait !
              </span>
            )}
          </div>
        )}
      </div>

      {/* Requirements list - more compact for mobile */}
      <div
        className={`grid ${
          isNative
            ? 'grid-cols-1 gap-y-1'
            : 'sm:grid-cols-2 grid-cols-1 gap-y-1.5'
        } gap-x-3`}
      >
        {requirements.map((requirement, index) => (
          <div
            key={requirement.id}
            className={`flex items-center transition-all duration-300 ease-in-out ${
              animateItems
                ? 'translate-y-0 opacity-100'
                : 'translate-y-2 opacity-0'
            }`}
            style={{ transitionDelay: `${index * 50}ms` }}
          >
            {requirement.isMet ? (
              <CheckCircle2
                className={`text-green-500 ${
                  isNative ? 'w-3 h-3' : 'w-3.5 h-3.5'
                } mr-1 flex-shrink-0`}
              />
            ) : (
              <XCircle
                className={`text-red-500 ${
                  isNative ? 'w-3 h-3' : 'w-3.5 h-3.5'
                } mr-1 flex-shrink-0`}
              />
            )}
            <span
              className={`${isNative ? 'text-[10px]' : 'text-[11px]'} ${
                requirement.isMet
                  ? 'text-gray-800 font-medium'
                  : 'text-gray-500'
              }`}
            >
              {requirement.label}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PasswordRequirements;
